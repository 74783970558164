<template>
  <b-modal
    :id="`insertion-order-upload-modal-${insertionOrderId}`"
    :modal-class="'default-modal'"
    header-class="justify-content-between"
    :hide-footer="true"
    @hidden="hidden"
  >

    <template #modal-header="{ close }">
      <div class="text-center">
        <h2 class="highlighted-color1">
          <span>Upload PI</span>
        </h2>
      </div>
      <!--begin::Close-->
      <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close" @click="close()">
          <span class="svg-icon svg-icon-1">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
            </svg>
          </span>
      </div>
      <!--end::Close-->
    </template>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="mt-4">
        <form class="default-form" @submit.prevent="">
          <input type="file" @change="changeFile" class="form-control">
          <div class="mt-3 text-center">
            <Button
              class="btn btn-primary"
              @click="submit"
              :loading="loader"
            >
              Salvar
            </Button>
          </div>
        </form>
    </div>
  </b-modal>
</template>

<script>

import InsertionOrdersService from '@/modules/insertion-orders/services/insertion-orders-service'
import NotificationService from '@/modules/notifications/services/notifications-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'

export default {
  name: 'InsertionOrderUploadModal',
  props: ['insertionOrderId'],
  components: {
    AlertWraper,
    Button
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      loader: false,
      form: {
        file: ''
      }
    }
  },
  methods: {
    /**
     * On modal hidden
     */
    hidden () {
      this.content = null
    },
    /**
     * Handle change on file
     */
    changeFile (e) {
      const file = e.target.files[0]
      this.form.file = file
    },
    /**
     * Submit form
     */
    async submit () {
      this.loader = true

      try {
        await InsertionOrdersService.uploadPDF(this.insertionOrderId, this.form)
        this.$bvModal.hide(`insertion-order-upload-modal-${this.insertionOrderId}`)
        const notification = {
          notification: {
            type: 'success',
            content: 'PI salvo com sucesso'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
        location.reload()
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    }
  }
}
</script>
