<template>
  <MainLayout>
    <template v-slot:toolbar>
      <!--begin::Page title-->
      <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <!--begin::Title-->
        <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Pedido de inserção - {{ insertionOrder.advertiser ? insertionOrder.advertiser.name : '' }}</h1>
        <!--end::Title-->
      </div>
      <!--begin::Actions-->
      <div class="d-flex align-items-center gap-2 gap-lg-3 position-relative">
        <div class="m-0">
          <router-link
            :to="{ name: 'insertion.orders.index' }"
            class="btn btn-sm btn-primary"
          >
            Voltar
          </router-link>
        </div>
        <!--end::Filter menu-->
      </div>
      <!--end::Actions-->
      <!--end::Page title-->
    </template>

    <!-- CONTENT -->
      <!--begin::details View-->
      <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <!--begin::Card header-->
        <div class="card-header cursor-pointer">
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bold m-0">Detalhes</h3>
          </div>
          <!--end::Card title-->
          <!--begin::Action-->
          <!--end::Action-->
        </div>
        <!--begin::Card header-->
        <!--begin::Card body-->
        <div class="card-body p-9">
          <template v-if="loader">
            <div class="py-5">
              <Loader size="big" />
            </div>
          </template>
          <template v-else>
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">ID</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.id }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Anunciante</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8">
                <router-link
                  :to="{ name: 'advertisers.show', params: { id: insertionOrder.advertiser.id } }"
                  class="fw-bold fs-6"
                >
                  {{ insertionOrder.advertiser.name }}
                </router-link>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Empresa</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8">
                <router-link
                  :to="{ name: 'companies.show', params: { id: insertionOrder.advertiser.company.id } }"
                  class="fw-bold fs-6"
                >
                  {{ insertionOrder.advertiser.company.name }}
                </router-link>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Valor Total do Negócio</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.total_business_amount / 100 | formatMoney }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Valor da PI</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.amount / 100 | formatMoney }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">PI credita valor na plataforma?</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.should_credit_amount_on_platform ? 'Sim' : 'Não' }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7" v-if="insertionOrder.should_credit_amount_on_platform">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Valor para plataforma</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.amount_for_platform / 100 | formatMoney }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Perfil de cobrança</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.billing_profile }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Forma de pagamento</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.payment_method == 'DEPOSIT' ? 'DEPÓSITO' : 'BOLETO' }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <template v-if="invoice && invoice.payment_method === 'BOLETO'">
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Link do boleto</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <a :href="invoice.boleto_url" target="_blank">{{ invoice.boleto_url }}</a>
                </div>
                <!--end::Col-->
              </div>
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Código de barras</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  {{ invoice.boleto_barcode }}
                </div>
                <!--end::Col-->
              </div>
            </template>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Data de validade</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.payment_expiration_date | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7" v-if="insertionOrder.order && insertionOrder.order.payment_status">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Status do pagamento</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.order.payment_status | formatPaymentStatus }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Emitir NF</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.should_issue_nfse ? 'Sim' : 'Não' }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Data de emissão NF</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6" v-if="insertionOrder.should_issue_nfse">{{ insertionOrder.nfse_issuance_date | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Descrição da NF</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.nfse_description }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7" v-if="insertionOrder.order && insertionOrder.order.nfse_status">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Status da NF</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">
                  {{ insertionOrder.order.nfse_status | formatNFSeStatus }}
                  <span class="ms-2" v-if="insertionOrder.order.nfse_status === 'WAITING'">
                    <a href="javascript:void(0);" v-b-modal="'issue-order-nfse-modal'">Emitir nota</a>
                  </span>
                </span>
                <IssueOrderNFSeModal
                  :order="insertionOrder.order"
                  @order-nfse-issued="handleIssuedNFSe"
                  v-if="insertionOrder.order.nfse_status === 'WAITING'"
                />

              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7" v-if="insertionOrder.order && insertionOrder.order.nfse_status === 'ISSUED'">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Nota fiscal</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">
                  <a href="javascript:void(0);" @click="downloadNFSe">Visualizar nota</a>
                </span>
              </div>
              <!--end::Col-->
              <div class="my-4" v-if="nfse || loaderNfse">
                <div class="pdf-wrapper col-md-8 mx-auto">
                  <template v-if="loaderNfse">
                    <div class="py-5">
                      <Loader size="big" />
                    </div>
                  </template>
                  <template v-if="nfse">
                    <div class="mb-3 text-center">
                      <Button
                        class="btn btn-primary"
                        @click.prevent="$refs.pdfComponent.print()"
                      >
                        Baixar
                      </Button>
                    </div>
                    <pdf ref="pdfComponent" :src="nfse"></pdf>
                  </template>
                </div>
              </div>
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7" v-if="insertionOrder.file">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">PI Assinado</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <a :href="insertionOrder.file" target="_blank" class="fw-bold fs-6">Link do arquivo</a>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Data de criação</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Data de última atualização</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.updated_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Ativo</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.is_active ? 'Sim' : 'Não' }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <div class="d-flex flex-column flex-sm-row mt-3 justify-content-center gap-3">
              <!-- Edit -->
              <router-link
                :to="{ name: 'insertion.orders.edit', params: { id: insertionOrder.id } }"
                class="btn btn-primary"
                v-if="
                  insertionOrder.is_active &&
                  !(insertionOrder.order && insertionOrder.order.payment_status === 'PAID')
                "
              >
                Editar
              </router-link><!-- Edit -->
              <!-- Download -->
              <button
                  class="btn btn-warning btn-color-dark"
                  v-b-modal="`insertion-order-download-modal-${insertionOrder.id}`"
                  v-if="!insertionOrder.file && insertionOrder.is_active"
                >
                  <i class="fa-solid fa-download"></i> Download do PI
              </button>
              <InsertionOrderDownloadModal
                :insertionOrder="insertionOrder"
                v-if="!insertionOrder.file && insertionOrder.is_active"
              /><!-- Download -->
              <!-- Upload -->
              <button
                class="btn btn-info"
                v-b-modal="`insertion-order-upload-modal-${insertionOrder.id}`"
                v-if="!insertionOrder.file && insertionOrder.is_active"
              >
                <i class="fa-solid fa-arrow-up-from-bracket"></i> Upload do PI
              </button>
              <InsertionOrderUploadModal
                :insertionOrderId="insertionOrder.id"
                v-if="!insertionOrder.file && insertionOrder.is_active"
              /><!-- Upload -->
              <!-- Remove -->
              <button
                  class="btn btn-danger"
                  v-if="!checkIfCanBeDestroyed"
                  v-b-modal="`remove-insertion-order-modal-${insertionOrder.id}`"
                >
                  Remover PI
              </button>
              <RemoveInsertionOrderModal
                :insertionOrderId="insertionOrder.id"
                v-if="!checkIfCanBeDestroyed"
              /><!-- Remove -->
              <!-- mark as paid -->
              <Button
                  class="btn btn-success"
                  v-if="insertionOrder.order && insertionOrder.is_active &&
                    insertionOrder.order.payment_status == 'WAITING_PAYMENT' &&
                    insertionOrder.payment_method == 'DEPOSIT'"
                  @click="markAsPaid()"
                  :loading="loaderButton"
                >
                  Marcar como pago
              </Button><!-- mark as paid -->
            </div>
          </template>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::details View-->
      <!-- END CONTENT -->
  </MainLayout>
</template>

<script>

import InsertionOrdersService from '@/modules/insertion-orders/services/insertion-orders-service'
import NotificationService from '@/modules/notifications/services/notifications-service'
import OrdersService from '@/modules/orders/services/orders-service'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'
import MainLayout from '@/components/layouts/MainLayout'
import moment from 'moment'
import InsertionOrderUploadModal from '@/components/insertion-orders/InsertionOrderUploadModal'
import InsertionOrderDownloadModal from '@/components/insertion-orders/InsertionOrderDownloadModal'
import IssueOrderNFSeModal from '@/components/orders/IssueOrderNFSeModal'
import RemoveInsertionOrderModal from '@/components/insertion-orders/RemoveInsertionOrderModal'
import pdf from 'vue-pdf'

export default {
  components: {
    Button,
    Loader,
    MainLayout,
    InsertionOrderUploadModal,
    InsertionOrderDownloadModal,
    IssueOrderNFSeModal,
    pdf,
    RemoveInsertionOrderModal
  },
  metaInfo () {
    return {
      title: this.insertionOrder ? 'PI' : ''
    }
  },
  data () {
    return {
      insertionOrder: '',
      loader: false,
      loaderButton: false,
      loaderNfse: false,
      showAlert: false,
      nfse: ''
    }
  },
  computed: {
    invoice () {
      return this.insertionOrder.order && this.insertionOrder.order.invoices.length > 0 ? this.insertionOrder.order.invoices[0] : null
    },
    checkIfCanBeDestroyed () {
      if (this.insertionOrder.is_active) {
        if (this.insertionOrder.order) {
          const currentMonth = moment().format('MM')
          const month = moment(this.invoice.nfse_issuance_date).format('MM')
          return this.insertionOrder.order.nfse_status === 'ISSUED' && currentMonth !== month
        } else return false
      }
      return true
    }
  },
  created () {
    this.getInsertionOrder()
  },
  methods: {
    /**
     * Download nfse
     */
    async downloadNFSe () {
      this.loaderNfse = true
      try {
        const data = await OrdersService.downloadNFSe(this.insertionOrder.order.id)
        var raw = window.atob(data)
        var rawLength = raw.length
        var array = new Uint8Array(new ArrayBuffer(rawLength))

        for (var i = 0; i < rawLength; i++) {
          array[i] = raw.charCodeAt(i)
        }
        this.nfse = array
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loaderNfse = false
    },
    /**
     * Handle issued nfse
     */
    async handleIssuedNFSe () {
      const notification = {
        notification: {
          type: 'success',
          content: 'Nota fiscal emitida com sucesso'
        }
      }
      NotificationService.createGlobalSuccessNotification(notification)
      this.getInsertionOrder()
    },
    /**
     * Get insertion order
     */
    async getInsertionOrder () {
      this.loader = true
      try {
        const insertionOrder = await InsertionOrdersService.getInsertionOrder(this.$route.params.id)
        this.insertionOrder = insertionOrder
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Mark as paid
     */
    async markAsPaid () {
      this.loaderButton = true
      try {
        await InsertionOrdersService.markAsPaid(this.$route.params.id)
        const notification = {
          notification: {
            type: 'success',
            content: 'Marcado como pago com sucesso'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
        this.getInsertionOrder()
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loaderButton = false
    }
  }
}
</script>
