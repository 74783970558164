<template>
  <b-modal
    :id="`insertion-order-download-modal-${insertionOrder.id}`"
    :modal-class="'default-modal'"
    size="lg"
    scrollable
    header-class="justify-content-between"
    :hide-footer="true"
    @hidden="hidden"
  >

    <template #modal-header="{ close }">
      <div class="text-center">
        <h2 class="highlighted-color1">
          <span>Download PI</span>
        </h2>
      </div>
      <!--begin::Close-->
      <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close" @click="close()">
          <span class="svg-icon svg-icon-1">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
            </svg>
          </span>
      </div>
      <!--end::Close-->
    </template>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div>
        <form class="default-form" @submit.prevent="">
          <div class="form-group row mb-5">
            <div class="col-md-6">
              <label class="form-label fw-semibold required">Campanha</label>
              <input type="text" class="form-control" v-model="form.campaign_name">
            </div>
            <div class="col-md-6">
              <label class="required form-label">Assinante</label>
              <select class="form-select" v-model.trim="form.signer" :disabled="disabled">
                <option value="ADVERTISER">Anunciante</option>
                <option value="COMPANY">Empresa</option>
              </select>
            </div>
          </div>
          <h4>Agência</h4>
          <div class="form-group row mb-5">
            <div class="col-md-6 mb-5">
              <!--begin::Label-->
              <label class="form-label fw-semibold required">Nome do contato</label>
              <!--end::Label-->
              <!--begin::Input-->
              <input type="text" class="form-control" v-model="form.agency_manager_name">
            </div>
            <div class="col-md-6 mb-5">
              <!--begin::Label-->
              <label class="form-label fw-semibold required">Email</label>
              <!--end::Label-->
              <!--begin::Input-->
              <input type="email" class="form-control" v-model="form.agency_manager_email">
            </div>
          </div>
          <h4>Responsável Financeiro</h4>
          <div class="form-group row mb-5">
            <div class="col-md-6 mb-5">
              <!--begin::Label-->
              <label class="form-label fw-semibold required">Nome</label>
              <!--end::Label-->
              <!--begin::Input-->
              <input type="text" class="form-control" v-model="form.financial_responsible_name">
            </div>
            <div class="col-md-6 mb-5">
              <!--begin::Label-->
              <label class="form-label fw-semibold required">Email</label>
              <!--end::Label-->
              <!--begin::Input-->
              <input type="email" class="form-control" v-model="form.financial_responsible_email">
            </div>
          </div>
          <div class="mb-5">
            <!--begin::Label-->
            <label class="form-label fw-semibold">Observações</label>
            <!--end::Label-->
            <!--begin::Input-->
            <textarea class="form-control" v-model="form.observation"></textarea>
          </div>
          <div class="form-group row mb-5">
            <div class="col-md-6">
              <label class="form-label fw-semibold">Coluna 1</label>
              <input type="text" class="form-control mb-2" v-model="form.column_1_title" placeholder="Título">
              <small>Colocar um item em cada linha.</small>
              <textarea class="form-control mt-2" rows="5" v-model="form.column_1_desc" placeholder="Descrição"></textarea>
            </div>
            <div class="col-md-6">
              <label class="form-label fw-semibold required">Coluna 2</label>
              <input type="text" class="form-control mb-2" v-model="form.column_2_title" placeholder="Título">
              <small>Colocar um item em cada linha.</small>
              <textarea class="form-control mt-2" rows="5" v-model="form.column_2_desc" placeholder="Descrição"></textarea>
            </div>
          </div>
          <div class="mt-3 text-center">
            <Button
              class="btn btn-primary"
              @click="submit"
              :loading="loader"
            >
              Download
            </Button>
          </div>
        </form>
    </div>
    <pdf ref="pdfComponent" :src="src" class="d-none"></pdf>
  </b-modal>
</template>

<script>

import InsertionOrdersService from '@/modules/insertion-orders/services/insertion-orders-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import pdf from 'vue-pdf'

export default {
  name: 'InsertionOrderDownloadModal',
  props: ['insertionOrder'],
  components: {
    AlertWraper,
    Button,
    pdf
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      loader: false,
      src: '',
      form: {
        campaign_name: '',
        signer: '',
        agency_manager_name: '',
        agency_manager_email: '',
        financial_responsible_name: '',
        financial_responsible_email: '',
        observation: '',
        column_1_title: '',
        column_2_title: 'campanha',
        column_1_desc: 'Alinhamento com o time de Suporte\nQuantidade de Peças\nDashboard\nAtualização de Relatório',
        column_2_desc: 'falecom@pixelroads.com.br\nVariável\nDashboard 24h após o início da campanha\nDiária'
      }
    }
  },
  methods: {
    /**
     * Submit form
     */
    async submit () {
      this.loader = true

      try {
        const data = await InsertionOrdersService.generatePDF(this.$route.params.id, this.form)

        var raw = window.atob(data)
        var rawLength = raw.length
        var array = new Uint8Array(new ArrayBuffer(rawLength))

        for (var i = 0; i < rawLength; i++) {
          array[i] = raw.charCodeAt(i)
        }
        this.src = array

        setTimeout(() => {
          this.$refs.pdfComponent.print(100, [1])

          this.$bvModal.hide(`insertion-order-download-modal-${this.insertionOrder.id}`)
        }, 1500)
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
        document.getElementsByClassName('modal-body')[0].scrollTop = 0
      }

      this.loader = false
    },
    /**
     * On modal hidden
     */
    hidden () {
      this.content = null
    }
  }
}
</script>
