<template>
  <b-modal
    id="issue-order-nfse-modal"
    :modal-class="'default-modal'"
    header-class="justify-content-between"
    :hide-footer="true"
    @shown="shown"
    @hidden="hidden"
  >

    <template #modal-header="{ close }">
      <div class="text-center">
        <h2 class="highlighted-color1">
          <span>Emitir nota fiscal</span>
        </h2>
      </div>
      <!--begin::Close-->
      <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close" @click="close()">
          <span class="svg-icon svg-icon-1">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
            </svg>
          </span>
      </div>
      <!--end::Close-->
    </template>

    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="mt-4 text-center">
      <template v-if="order">
        <p>Você tem certeza que deseja emitir a nota fiscal do pagamento de valor <strong>{{ order.amount/ 100 | formatMoney }}</strong>?</p>
        <Button
          type="button"
          class="btn btn-primary mt-4"
          @click="submit"
          :loading="loader"
        >
          Emitir nota fiscal
        </Button>
      </template>
    </div>
  </b-modal>
</template>

<script>

import OrdersService from '@/modules/orders/services/orders-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'

export default {
  name: 'IssueOrderNFSeModal',
  props: ['order'],
  components: {
    AlertWraper,
    Button
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      loader: false
    }
  },
  methods: {
    /**
     * On modal hidden
     */
    hidden () {
      this.content = null
    },
    /**
     * On modal shown
     */
    shown () {},
    async submit () {
      this.loader = true
      try {
        await OrdersService.issueNFSe(this.order.id)
        this.$emit('order-nfse-issued', this.order)
        this.$bvModal.hide('issue-order-nfse-modal')
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    }
  }
}
</script>
